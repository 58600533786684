<template>
    <div class="jumbotron" :class="[currentLang, { isTouch: isTouchDevice, 'is-prerender': isPrerender }]">
        <div style="width: 0px; height: 0px; position: absolute; top: -1px; left: -1px; overflow: hidden;">
            <img class="eve" src="/images/eve.jpg?202104" alt="meitu eve model" />
            {{ $t('metaInfo.title') || 'meituEve' }}
        </div>
        <pageHeader />
        <router-view></router-view>
        <pageFooter />

        <a href="javascript:;" class="fixed-contactus" @click="onChatnow" v-if="!isContactPage">
            <img class="fixed-contactus-icon" src="@assets/images/contact.png" alt="" />
            <span class="poppins text">{{ $t('contact') }}</span>
        </a>

        <transition>
            <div class="contact-modal" v-if="isShowContactModal">
                <contactForm @success="onContactSuccess" @send="onContactSend"></contactForm>
                <a href="javascript:;" class="contact-modal-close" @click="onCloseContactModal"></a>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import pageHeader from './components/header.vue';
import pageFooter from './components/footer.vue';
import contactForm from './components/contactForm.vue';

// import * as htmlToImage from 'html-to-image';

export default {
    metaInfo() {
        return this.metaData;
    },
    components: {
        pageHeader,
        pageFooter,
        contactForm,
    },
    data() {
        return {
            contactSource: '',
            isShowContactModal: false,
            isContactPage: this.$route.path.indexOf('contact') >= 0,
            isTouchDevice: 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0,
            isPrerender: window.__PRERENDER_INJECTED,
        };
    },
    computed: {
        ...mapState({
            currentLang: state => state.route.params.lang || 'zh',
        }),
    },
    watch: {
        currentLang() {
            this.metaChange();
        },
        '$route.meta': {
            handler() {
                this.metaChange();
            },
        },
        '$route.path': {
            handler() {
                this.isContactPage = this.$route.name === 'contact';
            },
        },
    },
    methods: {
        onChatnow() {
            // htmlToImage
            //     .toPng(document.getElementById('footer'))
            //     .then(dataUrl => {
            //         var img = new Image();
            //         img.src = dataUrl;
            //         document.body.appendChild(img);
            //     })
            //     .catch(error => {
            //         console.error('oops, something went wrong!', error);
            //     });
            this.$track('chatnow_clk');
            this.mxShowContactModal({
                source: 'chatnow',
            });
        },
        metaChange() {
            let language = this.currentLang;
            let title, keywords, description;
            let meta = this.$route.meta;
            let i18nMeta = this.$t('metaInfo');
            if (meta && meta[language]) {
                title = meta[language].title;
                keywords = meta[language].keywords;
                description = meta[language].description;
            }
            document.title = title || i18nMeta.title || '';
            document.querySelector(`meta[name="keywords"]`).setAttribute('content', keywords || i18nMeta.keywords || '');
            document.querySelector(`meta[name="description"]`).setAttribute('content', description || i18nMeta.description || '');

            if (i18nMeta.htmlLang && document.documentElement.setAttribute) {
                document.documentElement.setAttribute('lang', i18nMeta.htmlLang);
            }
            var head = document.getElementsByTagName('head')[0];
            var canonical = document.querySelector(`link[rel="canonical"]`);
            if (!canonical) {
                canonical = document.createElement('link');
                canonical.rel = 'canonical';
                head.appendChild(canonical);
            }
            canonical.href = (this.currentLang === 'zh' || this.currentLang === 'tw' ? 'https://eve.meitu.com' : 'https://evelabinsight.com') + this.$route.path;
            if (meta && meta.alternateMap) {
                for (let alternate in meta.alternateMap) {
                    var link = document.querySelector(`link[hreflang="${alternate}"]`);
                    if (!link) {
                        link = document.createElement('link');
                        link.hreflang = alternate;
                        link.rel = 'alternate';
                        head.appendChild(link);
                    }
                    link.href = meta.alternateMap[alternate];
                }
            }
        },
        onContactSuccess(data) {
            this.isShowContactModal = false;
            this.$track('filling_pop_success_imp', {
                source: this.contactSource,
            });
            if (this.currentLang === 'zh') {
                this.$toast.center(this.$t('contactSuccessToast'));
            } else {
                this.$router.push({
                    name: 'contact',
                    params: {
                        showMeeting: true,
                        data,
                    },
                });
            }
        },
        onContactSend() {
            this.$track('filling_pop_send_clk', {
                source: this.contactSource,
            });
        },
        onCloseContactModal() {
            this.isShowContactModal = false;
        },
    },
    mounted() {
        this.isContactPage = this.$route.name === 'contact';

        this.metaChange();

        this.$bus.$on('contact-modal', (params = {}) => {
            this.isShowContactModal = true;
            if (params.source) {
                this.contactSource = params.source;
                this.$track('filling_pop_imp', {
                    source: params.source,
                });
            }
        });
        if (process.env.VUE_APP_BUILD_FOR === 'evelabinsight') {
            var head = document.getElementsByTagName('head')[0];
            var healthAndBeautyBusinessJson = {
                '@context': 'http://schema.org',
                '@type': 'HealthAndBeautyBusiness',
                name: 'EveLabInsight',
                address: [
                    {
                        '@type': 'PostalAddress',
                        addressLocality: 'Singapore',
                        streetAddress: '80 Robinson Road, #02-00',
                        addressCountry: 'Singapore',
                    },
                    {
                        '@type': 'PostalAddress',
                        addressLocality: 'Xiamen',
                        streetAddress: 'Meifeng Science Park, Xike Town, Building 2, Tong’an District',
                        addressCountry: 'China',
                    },
                    {
                        '@type': 'PostalAddress',
                        addressLocality: 'Beijing',
                        streetAddress: '19F, Block D, Yousheng Building, No. 28 Chengfu Road, Haidian District',
                        addressCountry: 'China',
                    },
                    {
                        '@type': 'PostalAddress',
                        addressLocality: 'Beijing',
                        streetAddress: '7F, Block B/C, Yousheng Building, No. 28 Chengfu Road, Haidian District',
                        addressCountry: 'China',
                    },
                ],
                branchCode: ['HQ-Singapore', 'Branch-Xiamen', 'Branch-Beijing-1', 'Branch-Beijing-2'],
            };
            var logo = location.origin + require('@assets/svg/en/logo_black.svg');
            var organizationJson = {
                '@context': 'https://schema.org',
                '@type': 'Organization',
                name: 'EveLab Insight',
                description: 'EveLab Insight provides a revolutionary experience for beauty and skin care consultations with industry-leading technology and artificial intelligence skin analysis algorithms.',
                url: `https://evelabinsight.com/${currentLang}/`,
                logo,
                email: 'eve@evelabinsight.com',
                award: ['2018 ISIC First Place - Skin Lesion Segmentation Task', '2016 FDDB Top 1 Facial Recognition Algorithm Accuracy', '2018 ECCV PIRM First Place - Perceptual Image Enhancement', '2019 NTIRE First Place - Image Enhancement Track', '2019 NTIRE Third place - Image Dehazing Challenge'],
                makesOffer: [
                    {
                        '@type': 'Offer',
                        itemOffered: {
                            '@type': 'Product',
                            name: this.$t('eveV'),
                        },
                    },
                    {
                        '@type': 'Offer',
                        itemOffered: {
                            '@type': 'Product',
                            name: this.$t('eve'),
                        },
                    },
                    {
                        '@type': 'Offer',
                        itemOffered: {
                            '@type': 'Product',
                            name: this.$t('mirror'),
                        },
                    },
                    {
                        '@type': 'Offer',
                        itemOffered: {
                            '@type': 'Product',
                            name: this.$t('meitukey'),
                        },
                    },
                    {
                        '@type': 'Offer',
                        itemOffered: {
                            '@type': 'Product',
                            name: this.$t('virtualMakeup'),
                        },
                    },
                    {
                        '@type': 'Offer',
                        itemOffered: {
                            '@type': 'Product',
                            name: this.$t('virtualHair'),
                        },
                    },
                    {
                        '@type': 'Offer',
                        itemOffered: {
                            '@type': 'Product',
                            name: this.$t('accessories'),
                        },
                    },
                    {
                        '@type': 'Offer',
                        itemOffered: {
                            '@type': 'Product',
                            name: this.$t('contactLenses'),
                        },
                    },
                ],
            };
            var healthAndBeautyBusinessSchema = document.getElementById('healthAndBeautyBusinessSchema');
            if (!healthAndBeautyBusinessSchema) {
                healthAndBeautyBusinessSchema = document.createElement('script');
                healthAndBeautyBusinessSchema.type = 'application/ld+json';
                healthAndBeautyBusinessSchema.id = 'healthAndBeautyBusinessSchema';
                head.appendChild(healthAndBeautyBusinessSchema);
            }
            healthAndBeautyBusinessSchema.text = JSON.stringify(healthAndBeautyBusinessJson);

            var organizationSchema = document.getElementById('organizationSchema');
            if (!organizationSchema) {
                organizationSchema = document.createElement('script');
                organizationSchema.type = 'application/ld+json';
                organizationSchema.id = 'organizationSchema';
                head.appendChild(organizationSchema);
            }
            organizationSchema.text = JSON.stringify(organizationJson);
        }
    },
};
</script>

<style lang="scss">
.jumbotron {
    &.ja {
        font-family: 'SF Pro JP', 'SF Pro Text', 'SF Pro Icons', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro W3', メイリオ, Meiryo, 'ＭＳ Ｐゴシック', 'Helvetica';
    }
    &.ko {
        word-break: keep-all;
    }
    .contact-modal {
        &-close {
            background: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1l12.728 12.728M14 1L1.272 13.728' stroke='%23DCDCDC' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E") center center no-repeat;
        }
    }
    .fixed-contactus {
        background: #fff;
        position: fixed;
        z-index: 1000;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: 12px;
        font-weight: 700;
        transition: all .5s;

        .text {
            transform: scale(0.75);
            white-space: nowrap;
            // fix safari 会出现一条横线，改成纯色
            color: #00C8C8;
            // background-image: -webkit-linear-gradient(left, #3EDCE6, #4DBFFF);
            // -webkit-background-clip: text;
            // -webkit-text-fill-color: transparent;
        }

        &:hover {
            opacity: 0.96;
            box-shadow: 0 0 24px rgba(0, 0, 0, 0.05);
        }
    }
    @media only screen and (min-width: 769px) {
        .fixed-contactus {
            right: 30px;
            bottom: 100px;
            width: 100px;
            height: 100px;
            border-radius: 100px;
            box-shadow: 0 0 24px rgba(0, 0, 0, 0.1);

            &-icon {
                display: block;
                width: 50px;
                height: 50px;
            }
        }
        .contact-modal {
            position: fixed;
            width: 683px;
            max-height: 90%;
            max-height: calc(100% - 80px);
            box-sizing: border-box;
            background: #fff;
            border-radius: 20px;
            left: 50%;
            top: 50%;
            overflow: hidden;
            transform: translate(-50%, -50%);
            z-index: 1100;
            box-shadow: 0 0 24px rgba(0, 0, 0, 0.1);
            display: flex;
            flex-direction: column;

            .contact-form {
                flex: 1;
                padding-bottom: 40px;
                overflow-y: auto;
            }

            &-close {
                position: absolute;
                top: 20px;
                right: 20px;
                background-size: 15px;
                width: 21px;
                height: 21px;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        .fixed-contactus {
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 0.5rem;
            right: 0.16rem;
            bottom: 0.5rem;
            box-shadow: 0 0 0.12rem rgba(0, 0, 0, 0.1);

            &-icon {
                display: block;
                width: 0.2rem;
                height: 0.2rem;
            }

            .text {
                display: none;
            }
        }
        .contact-modal {
            position: fixed;
            width: 3.38rem;
            height: 4.9rem;
            background: #fff;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 1100;
            box-sizing: border-box;
            box-shadow: 0 0 24px rgba(0, 0, 0, 0.1);
            border-radius: 0.2rem;

            .contact-form {
                height: 100%;
                display: flex;
                flex-direction: column;
                overflow: hidden;

                &-body {
                    padding: 0.4rem 0.3rem;
                }
                &-agree,
                &-foot {
                    padding: 0 0.3rem;
                }
                &-agree {
                    position: relative;
                    &:after {
                        content: '';
                        width: 100%;
                        height: 0.6rem;
                        background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.9) 60%, rgba(255, 255, 255, 1));
                        position: absolute;
                        left: 0;
                        bottom: 100%;
                    }
                }
                &-foot {
                    padding-bottom: 0.28rem;
                }

                &-item {
                    width: 1.27rem;
                }

                &-body {
                    flex: 1;
                    overflow-y: auto;
                }
            }

            &-close {
                position: absolute;
                top: 0.2rem;
                right: 0.2rem;
                background-size: 0.15rem;
                width: 0.21rem;
                height: 0.21rem;
            }
        }
    }
}
.is-prerender {
    .swiper-container {
        opacity: 1 !important;
    }
    .swiper-wrapper {
        transform: translate3d(-100%, 0px, 0px) !important;
    }
    .home-banner-swiper-item,
    .solutions-banner-swiper-item {
        width: 100% !important;
    }
}
</style>
